import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"campaignusers",
        "id":"campaign_user_id",
        "subid":"campaign_user_id",
        "options":["edit","insert","delete"],
        "nameSingle":"campaign user",
        "nameMultiple":"campaign users",
        "order":"campaign_user_id",
        "fields":{
            "field1":{
                "name":"User ID",
                "field":"campaign_user_user_id",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Email",
                "field":"user_email",
                "type":"Display",
                "subtype":"Number",
                "required":false,
                "list":true,
                "edit":false,
            },
            "field3":{
                "name":"End date",
                "field":"campaign_user_end_date",
                "type":"DateInput",
                "list":true,
                "required":true,
            },
        }
    }
    
}